
import React, { Component } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Translation } from 'react-i18next';


class Notice extends Component {

  render() {

    return (
      <div>
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">



            <div className="row content">
              <div className="col-lg-6">
                <p>
                  <Translation>{(t) => <span>{t("notice_text1")}</span>}</Translation>
                </p>
                <p><Translation>{(t) => <span>{t("notice_text2")}</span>}</Translation></p>
                <p><Translation>{(t) => <span>{t("notice_text3")}</span>}</Translation></p>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <p><Translation>{(t) => <span>{t("notice_text4")}</span>}</Translation> </p>
                <p><Translation>{(t) => <span>{t("notice_text5")}</span>}</Translation> </p>

              </div>
            </div>

          </div>
        </section>
      </div>
    )
  }
}
export default Notice;