
import React, {Component} from 'react';
import { Translation } from 'react-i18next';
import {Link} from 'react-router-dom';


class Fonctionnement extends Component {
    render() {
        return (
    <div>
                       <section className="about">
                  <div className="container" data-aos="fade-up">
                    <div className="section-title">
                      <h2>PRET ARGENT AUJOURD’HUI</h2>
                      <h5><Translation>{(t) => <span>{t("work_subtitle")}</span>}</Translation></h5>
                    </div>
                    <div className="row content">
                      <div className="col-lg-6">
                        <p>
                        <Translation>{(t) => <span>{t("work_text1")}</span>}</Translation>
                        </p>
                        <p><Translation>{(t) => <span>{t("work_text2")}</span>}</Translation></p>
                        <p>
                        <Translation>{(t) => <span>{t("work_text3")}</span>}</Translation>
                        </p>
                       
                        <Link to={'/new-lead'} className="btn-learn-more"><Translation>{(t) => <span>{t("work_button")}</span>}</Translation></Link>
                      </div>
                      <div className="col-lg-6 pt-4 pt-lg-0">
                       
                        <p><Translation>{(t) => <span>{t("work_text4")}</span>}</Translation></p>
                        <ul>
                          <li><i className="ri-check-double-line" /> <Translation>{(t) => <span>{t("work_text5")}</span>}</Translation></li>
                        </ul>
                        <p><Translation>{(t) => <span>{t("work_text6")}</span>}</Translation> </p>
                        <p><Translation>{(t) => <span>{t("work_text7")}</span>}</Translation></p>
                      </div>
                    </div>
                  </div>
                </section>
                
                <section id="about" className="why-us section-bg">
                  <div className="container-fluid" data-aos="fade-up">
                    <div className="row">
                      <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                        <div className="content">
                          <h3><Translation>{(t) => <span>{t("work_title")}</span>}</Translation><strong> <Translation>{(t) => <span>{t("work_title_bold")}</span>}</Translation></strong></h3>
                        </div>
                        <div className="accordion-list">
                          <ul>
                            <li>
                              <a data-bs-toggle="collapse" className="collapse" data-bs-target="#accordion-list-1"><span>1</span> <Translation>{(t) => <span>{t("list1")}</span>}</Translation> <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                              <div id="accordion-list-1" className="collapse show" data-bs-parent=".accordion-list">
                                <p>
                                <Translation>{(t) => <p>{t("work_list1")}</p>}</Translation>
                                </p>
                              </div>
                            </li>
                            <li>
                              <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed"><span>2</span> <Translation>{(t) => <span>{t("list2")}</span>}</Translation> <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                              <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                                <p>
                                <Translation>{(t) => <p>{t("work_list2")}</p>}</Translation>
                                </p>
                              </div>
                            </li>
                            <li>
                              <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed"><span>3</span> <Translation>{(t) => <span>{t("list3")}</span>}</Translation><i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                              <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                                <p>
                                <Translation>{(t) => <p>{t("work_list3")}</p>}</Translation>
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{backgroundImage: 'url("assets/img/fonctionnement.webp")'}} data-aos="zoom-in" data-aos-delay={150}>&nbsp;</div>
                    </div>
                  </div>
                </section>

         
    </div>
  )
}
}
export default  Fonctionnement;