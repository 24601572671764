
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { Translation } from 'react-i18next';


class Footer extends Component {
    render() {
        return (
    <div>
              {/* ======= Footer ======= */}
              <footer id="footer">
                <div className="footer-top">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-3 col-md-6 footer-contact">
                        <h3>Argent <br/> Aujourd’hui</h3>
                      </div>
                      <div className="col-lg-3 col-md-6 footer-links"></div>
                      <div className="col-lg-3 col-md-6 footer-links">
                        <h4><Translation>{(t) => <span>{t("footer_title1")}</span>}</Translation></h4>
                        <ul>
                          <li><i className="bx bx-chevron-right" /> <a href="/"><Translation>{(t) => <span>{t("navbar_title1")}</span>}</Translation></a></li>
                          <li><i className="bx bx-chevron-right" /> <a href="#about"><Translation>{(t) => <span>{t("navbar_title2")}</span>}</Translation></a></li>
                          <li><i className="bx bx-chevron-right" /> <a href="#faq"><Translation>{(t) => <span>{t("navbar_title3")}</span>}</Translation></a></li>
                          <li><i className="bx bx-chevron-right" /> <Link to={'/politique_confidentialite'} className="getstarted scrollto me-2"><Translation>{(t) => <span>{t("footer_title3")}</span>}</Translation></Link></li>

                        </ul>
                      </div>
                      <div className="col-lg-3 col-md-6 footer-links">
                        <h4><Translation>{(t) => <span>{t("footer_title2")}</span>}</Translation> </h4>
                        <ul>
                        <li>
                          <i className="bx bx-chevron-right" /> 
                          <Link to={'/new-lead'} className="btn-learn-more"><Translation>{(t) => <span>{t("navbar_button")}</span>}</Translation></Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container footer-bottom clearfix">
                  <div className="copyright">
                    © Copyright <strong><span>Argent Aujourd'hui</span></strong>.  <Translation>{(t) => <span>{t("footer_copyright")}</span>}</Translation>
                  </div>
                  <div className="credits">
                
                  <Translation>{(t) => <span>{t("navbar_title1")}</span>}</Translation> <a href="#">Voxo Media</a>
                  </div>
                </div>
              </footer>
              {/* End Footer */}
         
    </div>
  )
}
}
export default  Footer;
