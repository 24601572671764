import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import Header from './Navigattion/Header';
import Footer from './Navigattion/Footer';
import Fonctionnement from './Components/Fonctionnement';
import Faq from './Components/Faq';
import Slider from './Components/Slider';
import LogoCompagnie from './Components/LogoCompagnie';
import Notice from './Components/Notice';
import Notice1 from './Components/Notice1';
import Notice2 from './Components/Notice2';
import Section1 from './Components/Section1';
import Section2 from './Components/Section2';
import { Seo } from '../helpers/Seo';

class Home extends Component {
  render() {
    return (
        <div>
            <Seo
                title="Prêt personnel 100$ approuvé sans document au Québec | Argent Aujourd'hui"
                description="Comparez facilement les prêts personnels approuvés sans document sur Argent Aujourd'hui. Trouvez l'option idéale pour vos besoins financiers."
                type="webapp"
                name="Argent Aujourd'hui"
            />
            <div id='oopss'>
                <div id='error-text'>
                    <img src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg" alt="404"/>
                    <span className="title_404">404 PAGE</span>
                    <p className="p-a">
                        Page introuvable</p>

                    <a href="https://argentaujourdhui.com" className="link">
                        Retour à l'acceuil  &#8618;</a>
                </div>
            </div>
        </div>
    );
  }
}

export default Home;

