import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import Header from './Navigattion/Header';
import Footer from './Navigattion/Footer';
import Fonctionnement from './Components/Fonctionnement';
import Faq from './Components/Faq';
import Slider from './Components/Slider';
import LogoCompagnie from './Components/LogoCompagnie';
import Notice from './Components/Notice';
import Notice1 from './Components/Notice1';
import Notice2 from './Components/Notice2';
import Section1 from './Components/Section1';
import Section2 from './Components/Section2';
import { Seo } from '../helpers/Seo';

class Home extends Component {
  render() {
    return (
      <div>
        <Seo
          title="Prêt personnel 100$ approuvé sans document au Québec | Argent Aujourd'hui"
          description="Comparez facilement les prêts personnels approuvés sans document sur Argent Aujourd'hui. Trouvez l'option idéale pour vos besoins financiers."
          type="webapp"
          name="Argent Aujourd'hui"
          url_content="https://argentaujourdhui.com"
          url_external_content="https://goodgoodfinance.com/"
        />

        <Header />
        <Slider />
        <main id="main">
          <Fonctionnement />
          <Faq />
          <Notice />
          <Section1 />
          <Notice1 />
          <Section2 />
          <Notice2 />
        </main>
        <Footer />
      </div>
    );
  }
}
export default Home;

