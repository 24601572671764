
import React, {Component} from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Translation } from 'react-i18next';


class Notice2 extends Component {
  
    render() {
      
        return (
    <div>
    <section id="about2" className="about2">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h5><Translation>{(t) => <span>{t("notice2_title1")}</span>}</Translation></h5>
        </div>

        <div className="row content">
          <div className="col-lg-6">
            <p><Translation>{(t) => <span>{t("notice2_text1")}</span>}</Translation></p>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
          <p><Translation>{(t) => <span>{t("notice2_text2")}</span>}</Translation></p>

          </div>
        </div>

      </div>
    </section>
    </div>
  )
}
}
export default  Notice2;