
import React, { Component } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Icon } from '@iconify/react';
import { Translation } from 'react-i18next';


class Section1 extends Component {

  render() {

    return (
      <div>
        <section id="section1" className="section1 section-bg">
          <div className="container" data-aos="fade-up">
            <div className="row content">
              <div class="col-sm-12 col-md-6 col-lg-4 text-center">
                <div class="card m-2 card-no-background">
                  <div class="card-body">
                    <div class="iconfont-wrapper mb-2">
                      <Icon icon="ant-design:smile-outlined" width="80" />
                    </div>
                    <h5 class="card-title"><Translation>{(t) => <span>{t("section1_title1")}</span>}</Translation></h5>
                    <p class="card-text"><Translation>{(t) => <span>{t("section1_content1")}</span>}</Translation></p>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 text-center">
                <div class="card m-2 card-no-background">
                  <div class="card-body">
                    <div class="iconfont-wrapper mb-3">
                      <Icon icon="simple-line-icons:like" width="70" />
                    </div>
                    <h5 class="card-title"><Translation>{(t) => <span>{t("section1_title2")}</span>}</Translation></h5>
                    <p class="card-text"><Translation>{(t) => <span>{t("section1_content2")}</span>}</Translation></p>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 text-center">
                <div class="card m-2 card-no-background">
                  <div class="card-body">
                    <div class="iconfont-wrapper mb-2">
                      <Icon icon="tabler:rotate" width="80" />
                    </div>
                    <h5 class="card-title"><Translation>{(t) => <span>{t("section1_title3")}</span>}</Translation></h5>
                    <p class="card-text"><Translation>{(t) => <span>{t("section1_content3")}</span>}</Translation></p>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </section>
      </div>
    )
  }
}
export default Section1;