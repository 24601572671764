
import React, {Component} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Translation } from 'react-i18next';
import i18n from "i18next";
import {initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";



i18n
.use(LanguageDetector)
.use(initReactI18next)
.use(HttpApi)
.init({
  supportedLngs: ['EN', 'FR'],
  fallbackLng: 'FR',
  backend: {
    loadPath: './assets/locales/{{lng}}/translation.json',
  },
  react: {useSuspense: true,
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i"],},
});


class Header extends Component {
  constructor(props) {
    super(props);
      var userLang = navigator.language || navigator.userLanguage;
      var lang

      switch(userLang) {
        case 'fr-FR':
          lang = 'FR'
          break;
        case 'fr-CA':
          lang = 'FR'
          break;
        case 'en-US':
          lang = 'EN'
          break;
        case 'en-CA':
          lang = 'EN'
          break;
        default:
          lang = 'FR'
      }

    this.state = {
      language: lang ? 'EN' : 'FR'
    };
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  handleLanguageChange(e) {
    this.setState({
      language: this.state.language === this.lang ? 'EN' : 'FR'
    });
    i18n.changeLanguage(this.state.language);
    const newLang = e.target.value;


    window.location.href = '/'+ newLang;
  }



    render() {
      const { t } = this.props;


        return (
    <div>
  
    <Navbar bg="light" expand="lg" fixed="top">
      <Container>
        <Navbar.Brand href="/">
          <img
            src='assets/img/logo-red-white.webp'
            width="150px"
            className="logo me-auto navbarStyle"
            alt="ARGENT AUJOURD’HUI!"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          </Nav>
             <Nav.Link href="/">
               <Translation>{(t) => <span>{t("navbar_title1")}</span>}</Translation>
            </Nav.Link>
            <Nav.Link className='is-active' href="/#about">
               <Translation>{(t) => <span>{t("navbar_title2")}</span>}</Translation>
            </Nav.Link>
            <Nav.Link href="/#faq">
              <Translation>{(t) => <span>{t("navbar_title3")}</span>}</Translation>
            </Nav.Link>
          <Nav.Link>
            <select className="form-select" value={i18n.language} onChange={this.handleLanguageChange}>
              <option value="FR" selected={this.state.language}>Français</option>
              <option value="EN" selected={this.state.language}>English</option>
            </select>


          </Nav.Link>
          <Link to={'/new-lead'} className="getstarted scrollto me-2"><Translation>{(t) =>
              <span>{t("navbar_button")}</span>}</Translation></Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  )
}
}
export default  Header;