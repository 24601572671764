
import React, { Component } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Icon } from '@iconify/react';
import { Translation } from 'react-i18next';


class Section2 extends Component {

  render() {

    return (
      <div>
        <section id="section2" className="section2 section-bg">
          <div className="container" data-aos="fade-up">
            <div className="row content text-center mb-2">
              <h3><Translation>{(t) => <span>{t("section2_main_title1")}</span>}</Translation></h3>
            </div>
            <div className="row content">
              <div class="col-sm-12 col-md-6 col-lg-4 text-center">
                <div class="card m-1 card-no-background">
                  <div class="card-body">
                    <div class="iconfont-wrapper mb-2">
                      <Icon icon="circum:search" width="60" />
                    </div>
                    <h5 class="card-title"><Translation>{(t) => <span>{t("section2_title1")}</span>}</Translation></h5>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 text-center">
                <div class="card m-1 card-no-background">
                  <div class="card-body">
                    <div class="iconfont-wrapper mb-3">
                      <Icon icon="clarity:cursor-hand-click-line" width="60" />
                    </div>
                    <h5 class="card-title"><Translation>{(t) => <span>{t("section2_title2")}</span>}</Translation></h5>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 text-center">
                <div class="card m-1 card-no-background">
                  <div class="card-body">
                    <div class="iconfont-wrapper mb-2">
                      <Icon icon="circum:face-smile" width="60" />
                    </div>
                    <h5 class="card-title"><Translation>{(t) => <span>{t("section2_title3")}</span>}</Translation></h5>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 text-center">
                <div class="card m-1 card-no-background">
                  <div class="card-body">
                    <div class="iconfont-wrapper mb-2">
                      <Icon icon="codicon:account" width="60" />
                    </div>
                    <h5 class="card-title"><Translation>{(t) => <span>{t("section2_title4")}</span>}</Translation></h5>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 text-center">
                <div class="card m-1 card-no-background">
                  <div class="card-body">
                    <div class="iconfont-wrapper mb-3">
                      <Icon icon="arcticons:fly-delta" width="60" />
                    </div>
                    <h5 class="card-title"><Translation>{(t) => <span>{t("section2_title5")}</span>}</Translation></h5>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 text-center">
                <div class="card m-1 card-no-background">
                  <div class="card-body">
                    <div class="iconfont-wrapper mb-2">
                      <Icon icon="ic:outline-album" width="60" />
                    </div>
                    <h5 class="card-title"><Translation>{(t) => <span>{t("section2_title6")}</span>}</Translation></h5>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 text-center">
                <div class="card m-1 card-no-background">
                  <div class="card-body">
                    <div class="iconfont-wrapper mb-2">
                      <Icon icon="fluent-mdl2:money" width="60" />
                    </div>
                    <h5 class="card-title"><Translation>{(t) => <span>{t("section2_title7")}</span>}</Translation></h5>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 text-center">
                <div class="card m-1 card-no-background">
                  <div class="card-body">
                    <div class="iconfont-wrapper mb-3">
                      <Icon icon="fa6-regular:id-card" width="60" />
                    </div>
                    <h5 class="card-title"><Translation>{(t) => <span>{t("section2_title8")}</span>}</Translation></h5>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 text-center">
                <div class="card m-1 card-no-background">
                  <div class="card-body">
                    <div class="simple-line-icons:magic-wand">
                      <Icon icon="simple-line-icons:magic-wand" width="60" />
                    </div>
                    <h5 class="card-title"><Translation>{(t) => <span>{t("section2_title9")}</span>}</Translation></h5>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </section>
      </div>
    )
  }
}
export default Section2;